<template>
  <div id="listbox">
    <img
      src="@/assets/img/YonghuiSync/post2.png"
      v-show="![1].includes(+$route.query.type)"
    />
    <!-- <h5>如有疑问，请通过咚咚联系下单店铺的在线客服。</h5> -->
    <ul class="lists">
      <li v-for="item in lists" :key="item.id">
        <div class="header">
          <!-- <img src="@/assets/img/YonghuiSync/jingdong.png" /> -->
          兑换码:
          <div class="omit">{{ item.redeCodeNo }}</div>
        </div>
        <!-- <div class="info">
          <div>订单号</div>
          <div class="omit">{{ item.ordNo }}</div>
        </div> -->
        <!-- gcShape 0：卡号卡密 1：卡密 2：卡号短链 3：卡号短链验证码 -->
        <div class="info">
          <div class="title">兑换时间</div>
          <div class="omit">
            {{ common.formatDate(item.excTime, '{yyyy}/{mm}/{dd}') || '-' }}
          </div>
        </div>
        <div class="info" v-show="![1].includes(+$route.query.type)">
          <div class="title">店铺订单号</div>
          <div class="omit">
            {{ item.outOrdNo || '-' }}
          </div>
        </div>
        <div class="info">
          <div class="title">商品名称</div>
          <div class="omit">
            {{ item.gcName || '-' }}
          </div>
        </div>
        <template v-if="[0].includes(item.gcShape)">
          <div class="info">
            <div class="title">卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div class="title">密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.cardPwd }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
        <div class="info" v-if="[1].includes(item.gcShape)">
          <div class="title">卡&nbsp;&nbsp;&nbsp;密</div>
          <div class="omit">{{ item.cardPwd }}</div>
          <div
            class="btn"
            v-clipboard:copy="item.cardPwd"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <div class="info" v-if="[2].includes(item.gcShape)">
          <div class="title">短&nbsp;&nbsp;&nbsp;链</div>
          <div class="omit">{{ item.shortUrl }}</div>
          <div
            class="btn"
            v-clipboard:copy="item.shortUrl"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <template v-if="[3].includes(item.gcShape)">
          <div class="info">
            <div class="title">卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div class="title">短&nbsp;&nbsp;&nbsp;链</div>
            <div class="omit">{{ item.shortUrl }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div class="title">密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.verifyCode }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.verifyCode"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
        <div class="info">
          <div class="title">有效期</div>
          <div class="omit">
            {{ common.formatDate(item.virtualEndTime, '{yyyy}/{mm}/{dd}') }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { listRedeemCode } from '@/api/YonghuiSync.js'
export default {
  data() {
    return {
      lists: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const { code, data, message } = await listRedeemCode(this.$route.query)
      if (code === 0) {
        this.lists = data
      } else {
        this.$toast.fail({
          message: message
        })
      }
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/YonghuiSync.less';
</style>
